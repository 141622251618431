// import logo from './logo.svg';
// import './App.css';
import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

const NavigationBar = React.lazy(() =>
  import("./components/navigation/NavigationBarDrop")
);
const Footer = React.lazy(() => import("./components/navigation/Footer"));

// const MaintenancePage = React.lazy(() => import("./pages/sub/MaintenancePage"));
const HomePage = React.lazy(() => import("./pages/homePage/HomePage"));
const AboutPage = React.lazy(() => import("./pages/about/AboutPage"));
const ToursPage = React.lazy(() => import("./pages/toursPage/ToursPage"));
const VisaPage = React.lazy(() => import("./pages/visaPage/VisaPage"));
const VisaCountry = React.lazy(() => import("./pages/visaCountry/VisaCountry"));
const PassportPage = React.lazy(() => import("./pages/passport/PassportPage"));
const InquirePage = React.lazy(() => import("./pages/inquire/InquirePage"));
const TestimonialsPage = React.lazy(() =>
  import("./pages/testimonialPage/TestimonialPage")
);
const SelectedTourPage = React.lazy(() =>
  import("./pages/homePage/pageComponents/selectedTourPage/SelectedTourPage")
);

function App() {
  return (
    <div>
      <HashRouter>
        <ScrollToTop />
        <Suspense>
          <Routes>
            <Route path="/" element={<NavigationBar />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/inbound" element={<ToursPage />} />
              <Route path="/outbound" element={<ToursPage />} />
              <Route path="/visa" element={<VisaPage />} />
              <Route path="/passport" element={<PassportPage />} />
              <Route path="/inquire" element={<InquirePage />} />

              <Route path="/japan" element={<VisaCountry />} />
              <Route path="/korea" element={<VisaCountry />} />
              <Route path="/china" element={<VisaCountry />} />
              <Route path="/schenegen" element={<VisaCountry />} />
              <Route path="/australia" element={<VisaCountry />} />
              <Route path="/canada" element={<VisaCountry />} />
              <Route path="/us" element={<VisaCountry />} />
              <Route path="/uae" element={<VisaCountry />} />
              <Route path="/uk" element={<VisaCountry />} />
              <Route path="/india" element={<VisaCountry />} />
              <Route path="/newzealand" element={<VisaCountry />} />

              <Route path="/tourist-extend" element={<InquirePage />} />
              <Route path="/resident-marriage" element={<InquirePage />} />
              <Route path="/quota" element={<InquirePage />} />
              <Route path="/retirement" element={<InquirePage />} />
              <Route path="/work" element={<InquirePage />} />
              <Route path="/missionary" element={<InquirePage />} />
              <Route path="/recognition" element={<InquirePage />} />
              <Route path="/former" element={<InquirePage />} />
              <Route path="/acr" element={<InquirePage />} />
              <Route path="/blacklist" element={<InquirePage />} />
              <Route path="/bail" element={<InquirePage />} />
              <Route path="/deport" element={<InquirePage />} />
              <Route path="/denied" element={<InquirePage />} />

              <Route path="/testimonials" element={<TestimonialsPage />} />
              <Route
                path="/selectedtourpage/:id"
                element={<SelectedTourPage />}
              />
            </Route>
          </Routes>
          <Footer path="/" element={<Footer />} />
        </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
